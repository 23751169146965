html,
body {
  padding: 0;
  margin: 0;
  --chakra-fonts-body: 'Montserrat', 'Inter', 'Roboto', -apple-system,
    BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  font-family: var(--chakra-fonts-body) !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

.dropzone {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 32px;
  border: dashed 2px rgb(187, 194, 205);
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  height: 96px;
  font-weight: 500;
  color: rgb(107, 112, 120);
  text-align: center;
}

.dropzone:hover {
  color: rgb(60, 63, 69);
  border: dashed 2px rgb(131, 137, 146);
}

.ag-theme-material * {
  font-family: var(--chakra-fonts-body) !important;
  font-size: 14px;
}

.ag-cell {
  padding-left: 16px;
  padding-right: 16px;
}

.ag-cell-value > * {
  line-height: normal;
}

.ag-header {
  background: #fff !important;
  border-bottom: var(--chakra-borders-1px);
  border-color: var(--chakra-colors-gray-100);
}

.ag-header-cell-text {
  font-weight: 600 !important;
  font-size: var(--chakra-fontSizes-xs) !important;
  color: #718096 !important;
  font-family: var(--chakra-fonts-heading) !important;
  text-transform: uppercase;
}

.ag-root-wrapper {
  border: none !important;
  border-radius: 16px !important;
  box-shadow: var(--chakra-shadows-md) !important;
}

.table-tiny th {
  padding-top: 10px;
  padding-bottom: 10px;
}

.table-tiny th,
.table-tiny td {
  padding-left: 16px;
  padding-right: 0px;
}

.table-tiny th:last-child,
.table-tiny td:last-child {
  padding-right: 16px;
}

.mentions-input {
  background-color: var(--chakra-colors-gray-100);
  border-radius: 8px;
  font-size: 14px;
  font-family: var(--chakra-fonts-body);
  width: 100%;
}

.mentions-input__input {
  padding: 8px 16px !important;
  font-size: 14px;
  font-family: var(--chakra-fonts-body);
  border: solid 2px transparent;
  border-radius: 8px;
  transition: all 0.2s;
}

.mentions-input__mention {
  background: var(--chakra-colors-orange-200);
  color: var(--chakra-colors-orange-800);
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 14px;
  font-family: var(--chakra-fonts-body);
}

.mentions-input__input:focus {
  border: solid 2px var(--chakra-colors-orange-200) !important;
  outline: none;
}

.mentions-input__highlighter {
  padding: 8px 16px !important;
  font-size: 14px !important;
  font-family: var(--chakra-fonts-body) !important;
  border-radius: 8px;
}

.mentions-input__highlighter strong {
  background-color: var(--chakra-colors-orange-200);
  border-radius: 4px;
}

.mentions-input__suggestions {
  background-color: var(--chakra-colors-gray-100);
  border-radius: 8px;
  padding: 8px !important;
  font-size: 14px;
  font-family: var(--chakra-fonts-body);
  width: 100%;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  border: solid 1px var(--chakra-colors-gray-200);
  position: absolute;
  bottom: 48px !important;
  top: auto !important;
}

.mentions-input__suggestions__item {
  padding: 8px !important;
  font-size: 14px;
  font-family: var(--chakra-fonts-body);
}

.chakra-portal {
  z-index: 999999999 !important;
  position: relative;
}

/* Tree Paths */
.tree-node-path {
  stroke: var(--chakra-colors-orange-400) !important;
  stroke-width: 2px;
}
